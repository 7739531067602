import type { IAuth } from '@wisestamp/common';
import { AuthLife } from './auth-life.js';
import { employeeAuthGateway } from '@wisestamp/employee-auth-gateway-sdk';

export const authLife = new AuthLife();

let auth: IAuth | undefined = undefined;

export async function initAuth(): Promise<boolean> {
  try {
    let wsRefreshToken = getWsToken();

    if (!wsRefreshToken) {
      try {
        await employeeAuthGateway.init();
        await employeeAuthGateway.alignCookie({});
      } catch (e) {
        console.error('align cookie failed:', e);
      }
      wsRefreshToken = getWsToken();
      if (!wsRefreshToken) return false;
    }

    auth = await authLife.init();
    await authLife.login(wsRefreshToken);
  } catch (e) {
    console.error('Exception during auth init', e);

    return false;
  }

  return true;
}

export function auth4Sdks(): IAuth {
  if (!auth) throw new Error('Too early: auth not initialized');

  return auth;
}

function getWsToken(): string | undefined {
  const wsTokenMarker = 'ws_employee_refresh_token=';
  const cookies = document.cookie;
  const markerStartIndex = cookies.indexOf(wsTokenMarker);

  if (markerStartIndex === -1) return undefined;

  const tokenStartIndex = markerStartIndex + wsTokenMarker.length;
  let tokenEndMarker: number | undefined = cookies.indexOf(
    ';',
    tokenStartIndex
  );
  if (tokenEndMarker === -1) tokenEndMarker = undefined;

  return cookies.substring(tokenStartIndex, tokenEndMarker);
}
