import { type IAuth } from '@wisestamp/common';
import {
  type SessionTokenPayload,
  employeeAuthGateway,
} from '@wisestamp/employee-auth-gateway-sdk';
import { employeePortalGateway } from '@msl/employee-portal-gateway-sdk';
import { AuthAutoRefresh } from './auth-auto-refresh.js';
import { jwtDecode } from './jwt.js';
import { currentUser } from '../session';

type Resolver = () => void;

/**
 * Maintains healthy session and access tokens required for communicating with gateways
 *
 * The tricky part is allowing SDKs to use this class (IAuth) before the *thing* was initialized\
 * Follow initPromise/initResolver
 */
export class AuthState implements IAuth {
  private refreshToken: string | undefined;
  private accessToken: string | undefined;
  private isInitDone: boolean = false;

  /** Used to await the initialization before trying to serve SDKs */
  private initPromise: Promise<void>;
  /**
   * Resolves the above init promise
   * Had to convince ts that this is always initialized in constructor with the '!'
   */
  private initResolver!: Resolver;

  /** One refresh promise for all */
  private refreshPromise: Promise<string> | undefined;

  /** Auto refresh */
  private autoRefresh = new AuthAutoRefresh(this);

  private destroyed = false;

  constructor() {
    this.initPromise = new Promise((resolve, _reject) => {
      this.initResolver = resolve;

      // TODO: set timeout for rejection?
    });
  }

  public async init(wsRefreshToken: string): Promise<void> {
    this.checkState();

    if (!wsRefreshToken?.trim()) throw new Error('auth.init: invalid wsToken');

    this.refreshToken = wsRefreshToken;

    // resolve the init promise
    this.initResolver();

    // refresh the token (will generate)
    await this.refresh();

    this.isInitDone = true;
  }

  public getCookieDomain() {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost')) {
      return 'localhost';
    } else if (hostname.includes('wisestamp-dev.com')) {
      return '.wisestamp-dev.com';
    } else {
      return '.wisestamp.com';
    }
  }

  public async destroy() {
    this.checkState();

    const refreshToken = this.refreshToken;
    this.refreshToken = undefined;
    this.accessToken = undefined;
    this.destroyed = true;
    this.autoRefresh.stop();

    await employeeAuthGateway.revokeToken({ refreshToken });

    document.cookie = `ws_employee_refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${this.getCookieDomain()}; path=/;`;
    location.pathname = '/signin';
  }

  private checkState() {
    if (this.destroyed) throw new Error('Trying to use a destroyed instance');
  }

  /** IAuth impl
   * returns the current access token if exist,
   * otherwise SDKs will call refresh()
   */
  public getCurrentAccessToken(): string | undefined {
    this.checkState();
    return this.accessToken;
  }

  /**
   * IAuth impl
   * Tells the auth state machine that the access token is invalid
   * Called by SDKs when they get a 401
   *
   * Multiple calls to this method while obtaining an access token are
   * guarded and should all resolve on the same promise and the same token
   * @returns the access token
   */
  public async refresh(): Promise<string> {
    this.checkState();

    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    this.refreshPromise = this.tryGetAccessToken();
    try {
      const accessToken = await this.refreshPromise;
      // what if it is undefined?
      return accessToken;
    } catch (e) {
      if (this.isInitDone) {
        location.pathname = '/signin';
      } else {
        throw e;
      }
    } finally {
      // Very important!
      this.refreshPromise = undefined;
    }
    return '';
  }

  private async tryGetAccessToken(): Promise<string> {
    this.accessToken = undefined;
    await this.initPromise;

    const res = await employeeAuthGateway.refreshTokens({
      refreshToken: this.refreshToken,
    });

    if (!res.ok) {
      if (res.reason === 'REFRESH_TOKEN_NOT_FOUND') {
        throw new Error('Refresh token not found');
      }
      throw new Error(`Token refresh failed: ${res.reason}`);
    }

    if (!res.result?.accessToken || !res.result?.refreshToken) {
      throw new Error('Invalid token');
    }

    this.refreshToken = res.result.refreshToken;
    this.accessToken = res.result.accessToken;

    const expires = new Date(
      Date.now() + 60 * 24 * 60 * 60 * 1000
    ).toUTCString(); // 60 days
    document.cookie = `ws_employee_refresh_token=${this.refreshToken}; expires=${expires}; domain=${this.getCookieDomain()}; path=/`;

    await this.notifyOthers();
    this.autoRefresh.start();

    return this.accessToken as string;
  }

  /** TODO: implement life-cycle events */
  private async notifyOthers() {
    if (!this.refreshToken) return;

    const { employeeId, accountId, domainId, email } =
      jwtDecode<SessionTokenPayload>(this.refreshToken).payload;

    const employee = await employeePortalGateway.getEmployeeInfo({});

    if (employee.name === undefined) {
      throw new Error('Employee name is undefined');
    }

    currentUser.init({
      employeeId,
      accountId,
      domainId,
      email,
      name: employee.name,
    });
  }
}
