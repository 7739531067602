<script setup lang="ts">
import { WsMenu, WsMenuItem, WsMenuSeparator } from '@mfl/common-components';
import { navigateTo } from '@mfl/framework';
import { onUnmounted, ref } from 'vue';

import { authLife } from '../auth';
import { currentUser, setUserNameEvent } from '../session';

const userName = ref(currentUser.info.name);

const unsubscribeUserNameSet = setUserNameEvent.subscribe((name) => {
  userName.value = name;
});

onUnmounted(() => {
  unsubscribeUserNameSet();
});
</script>

<template>
  <button
    aid="USER_MENU_BUTTON"
    class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 text-base font-bold text-gray-400"
  >
    {{ userName[0].toUpperCase() }}

    <WsMenu min-width="180px" aid="USER_MENU">
      <WsMenuItem
        icon="fa-light fa-user"
        href="/profile"
        aid="USER_MENU_ITEM_PROFILE"
        @click.prevent="navigateTo('/profile')"
      >
        Edit my details
      </WsMenuItem>

      <WsMenuSeparator />

      <WsMenuItem
        icon="fa-light fa-sign-out"
        aid="USER_MENU_ITEM_SIGN_OUT"
        @click="authLife.logout()"
      >
        Sign out
      </WsMenuItem>
    </WsMenu>
  </button>
</template>
