export enum EmployeePortalBootSequence {
  Normal,
  SkipPlugins,
  Stop,
}
import { initAuth } from './auth/auth-init';

import { registerHeader } from './header';

const SIGNIN_ROUTE = '/signin';

/**
 * Check use signin state and navigate to sign in if not signed in\
 * The signin MFE is stand alone and doesn't need other plugin to be loaded\
 *
 * Actual signin mechanism is not implemented yet, so we use a mock session-storage flag for testing
 */
export async function employeePortalBootstrap(): Promise<EmployeePortalBootSequence> {
  // const mockSignin = sessionStorage.getItem('MOCK_SIGNIN');

  const isSignedIn = await initAuth();

  const isInSignin = location.pathname === SIGNIN_ROUTE;

  // Not signed-in, navigate
  if (!isSignedIn && !isInSignin) {
    location.pathname = SIGNIN_ROUTE;

    return EmployeePortalBootSequence.Stop;
  }

  // Signed-in in signin route, navigate to home
  if (isSignedIn && isInSignin) {
    location.pathname = '';

    return EmployeePortalBootSequence.Stop;
  }

  if (!isSignedIn && isInSignin) {
    // load without plugins
    return EmployeePortalBootSequence.SkipPlugins;
  }

  registerHeader();

  return EmployeePortalBootSequence.Normal;
}
