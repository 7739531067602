import { Event } from '@mfl/framework';

export type BasicUserDetails = {
  accountId: string;
  domainId: string;
  employeeId: string;
  email: string;
  name: string;
};

// This event is triggered when updating the employee name, which happens when
// the employee details via the signature edit view or profile page. The user
// menu button in the header listens for it and reflects the new name.
export const setUserNameEvent = new Event<string>();

const triggerSetUserName = setUserNameEvent.init();

class CurrentUser {
  private basicInfo: BasicUserDetails | undefined;

  public init(info: BasicUserDetails): void {
    this.basicInfo = info;

    console.info('CURRENT USER', { info });
  }

  /**
   * return the user details, use this for destructuring\
   * Use the named ones for single field access (`currentUser.accountId()`)
   */
  public getInfo(): BasicUserDetails {
    // Asking for a user too early, throw
    if (!this.basicInfo) throw new Error('User info still not available');

    return this.basicInfo;
  }

  public get info(): BasicUserDetails {
    return this.getInfo();
  }

  /** Get the account ID */
  public get accountId(): string {
    return this.getInfo().accountId;
  }

  /**
   * Get the current domain ID ("context" domain)\
   * The domain ID is kept per browser tab - kept in sessionStorage
   */
  public get domainId(): string {
    return this.getInfo().domainId;
  }

  /** Get the employee ID */
  public get employeeId(): string {
    return this.getInfo().employeeId;
  }

  public get email(): string {
    return this.getInfo().email;
  }

  public setName(name: string): void {
    this.info.name = name;

    triggerSetUserName(name);
  }
}

export const currentUser = new CurrentUser();
