import { employeePortalGateway } from '@msl/employee-portal-gateway-sdk';
import { type IAuth } from '@wisestamp/common';
import { employeeAuthGateway } from '@wisestamp/employee-auth-gateway-sdk';

import { AuthState } from './auth-state.js';

/**
 * Lifecycle hooks and management of the state machine\
 * The state itself is managed by AuthState (auth-state.ts)\
 * The interface that SDKs require (IAuth from @wisestamp/common) is returned when init is called\
 * The frontend app must keep this instance and make it available to all
 *
 * It is critical that only one instance of both classes are created!
 */
export class AuthLife {
  private authState = new AuthState();
  private initialized = false;
  private loggedin = false;

  public async init(): Promise<IAuth> {
    if (this.initialized) throw new Error('already initialized');

    console.info('Initializing authentication management');
    this.initialized = true;

    const iAuth: IAuth = {
      getCurrentAccessToken: this.authState.getCurrentAccessToken.bind(
        this.authState
      ),
      refresh: this.authState.refresh.bind(this.authState),
    };

    await employeeAuthGateway.init(iAuth);
    await employeePortalGateway.init(iAuth);

    return iAuth;
  }

  /**
   * Provide the ws-token and domain Id\
   * After this method returns, valid tokens are accessible
   *
   * Even if this method throws and error it cannot be called again\
   * Token generation will be retried on the next call to authState.refresh
   */
  public async login(wsRefreshToken: string): Promise<void> {
    if (this.loggedin) throw new Error('already loggedin');

    this.loggedin = true;

    await this.authState.init(wsRefreshToken);
  }

  public async logout(): Promise<void> {
    await this.authState.destroy();
  }
}
